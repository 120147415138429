export default [
    {
        path: '/product',
        name: 'Product',
        component: () => import('./Product.vue')
    },
    {
        path: '/product/create',
        name: 'ProductCreate',
        component: () => import('./Create.vue')
    },
    {
        path: '/product/edit/:id',
        name: 'ProductEdit',
        component: () => import('./Edit.vue')
    },
    {
        path: '/product/:id',
        name: 'ProductShow',
        component: () => import('./Show.vue')
    },
    {
        path: '/product/stock/:venue_id/:product_id',
        name: 'ProductStockShowDetail',
        component: () => import('./product_stock/Show.vue')
    }

]
